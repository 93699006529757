/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    h4: "h4",
    ul: "ul",
    li: "li",
    blockquote: "blockquote",
    strong: "strong",
    pre: "pre",
    code: "code",
    a: "a",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Notes and Reference SQL Language"), "\n", React.createElement(_components.h3, null, "SQL Categories"), "\n", React.createElement(_components.p, null, "DML\n:Data Manipulation Language"), "\n", React.createElement(_components.p, null, "DDL\n:Data Definition language"), "\n", React.createElement(_components.h4, null, "DBMS Database Management System"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "oracle"), "\n", React.createElement(_components.li, null, "MySql"), "\n", React.createElement(_components.li, null, "SQLlite"), "\n"), "\n", React.createElement(_components.h3, null, "Quick Reference"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "📒 Note SQL is not case sensitive but it is general practice to distinguish fields"), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "not case sensitive"), "\n", React.createElement(_components.li, null, "not white space sensitive"), "\n", React.createElement(_components.li, null, "not semi collen sensitive (officially it should)"), "\n"), "\n", React.createElement(_components.p, null, "Though the addition of each of these formats are best practice and general convention."), "\n", React.createElement(_components.h3, null, "Important"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "String values in SQL are surrounded in single quotes")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "equality is a single =")), "\n"), "\n", React.createElement(_components.h4, null, "Select statement"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  }, "SELECT * FROM table;\n-- returns all columns from some table\n\nSELECT * FROM table WHERE condition\n-- returns all columns from some table where a condition is met\n\nSELECT * FROM database.table WHERE condition\n-- in some cases the database needs to be specified, though many SQL databases server have default databases that a query will be aplied to\n")), "\n", React.createElement(_components.p, null, "Examples Select:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  }, "SELECT FirstName FROM Employee;\n-- returns Firstname column from employee\n\nSELECT FirstName, LastName FROM Employee;\n-- returns 2 columns FirstName and LastName\n\nSELECT *  FROM Employee;\n-- returns all columns from employee table\n\nSELECT *  FROM Employee WHERE LastName = 'Smith';\n-- returns all columns where lastname is equal to Smith\n")), "\n", React.createElement(_components.h4, null, "Insert Statement"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  }, "INSERT INTO table (a,b,c)\n  VALUES (1,2,3)\n  ORDER BY b;\n")), "\n", React.createElement(_components.h4, null, "Update Statement"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  }, "UPDATE table SET a = 7, b =5;\n")), "\n", React.createElement(_components.h4, null, "Delete Statement"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  }, "DELETE FROM table WHERE a = 7;\n")), "\n", React.createElement(_components.h4, null, "Record match query"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  }, "SELECT COUNT( * ) FROM table;\n")), "\n", React.createElement(_components.h4, null, "Where"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "Where"), " clause is the predicate. Where is boolian. It is either true of false"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-SQL"
  })), "\n", React.createElement(_components.h2, null, "Popular SQL databases"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mysql.com/"
  }, "MySQL")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.postgresql.org/"
  }, "PostgreSQL")), "\n"), "\n", React.createElement(_components.h3, null, "Installation"), "\n", React.createElement(_components.p, null, "Upon download of dng mySQL shell and mySQL utilities expose mysql/bin to shell of choice"), "\n", React.createElement(_components.p, null, "Example:"), "\n", React.createElement(_components.p, null, "mySQL shell path and mySQL utilities path on system"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "usr/local/mysql/bin && usr/local/mysql-shell/bin\n")), "\n", React.createElement(_components.p, null, "navigate to shell profiles .bashrc, .bash_profile or .zshrc add"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "export PATH=${PATH}:/usr/local/mysql-shell/bin\nexport PATH=${PATH}:/usr/local/mysql/bin\n")), "\n", React.createElement(_components.p, null, "Open terminal of choice"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "mysql -u user-name -p\n")), "\n", React.createElement(_components.p, null, "enter:"), "\n", React.createElement(_components.p, null, "prompt for password"), "\n", React.createElement(_components.p, null, "Sucess, we have entered the mysql shell"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "> shell mysql show databases;\n//shows list of db's in current server\n")), "\n", React.createElement(_components.h2, null, "Notes on NoSQL Databases"), "\n", React.createElement(_components.h3, null, "Popular SQL databases"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://couchdb.apache.org/"
  }, "CouchDB")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://redis.io/"
  }, "Redis")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Resources and Reference"), "\n", React.createElement(_components.p, null, "Notes within this doc have been drawn from the following sources:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gist.github.com/hofmannsven/9164408"
  }, "MySQL shell CheatSheet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.codecademy.com/learn/connecting-javascript-and-sql/modules/learn-node-sqlite-module/cheatsheet"
  }, "node sqlite cheatsheet - CodeCademy")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
